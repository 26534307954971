<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb title=" " icon="" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <v-row>
      <v-col cols="12">
        <card-course :course="course" :fetch="courseFetch" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" pa-0>
        <v-row>
          <v-col cols="12" md="6">
            <card-list-dashboard
              heading="Professores"
              :fetch="teacherFetch"
              :items="teacher"
              :footerUrl="`/course/list/${this.$route.params.id}/${this.$route.params.course}/teachers`"
              footerText="Visualizar professores"
            ></card-list-dashboard>
          </v-col>
          <v-col cols="12" md="6">
            <card-list-dashboard
              heading="Alunos"
              :fetch="studentFetch"
              :items="student"
              :footerUrl="`/course/list/${this.$route.params.id}/${this.$route.params.course}/students`"
              footerText="Visualizar alunos"
            ></card-list-dashboard>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import md5 from '@/libs/md5'
import { mapMutations } from 'vuex'

export default {
  ///
  data: () => ({
    course: {},
    teacher: [],
    student: [],
    courses: [],
    courseFetch: false,
    coursesFetch: false,
    teacherFetch: false,
    applicationFetch: false,
    studentFetch: false,

    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school'
      }
    ]
  }),
  methods: {
    ...mapMutations(['setLoading']),
    async getCourseData (organizationId) {
      try {
        const classResponse = await this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}/courses/${this.$route.params.course}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.course = {
          ...classResponse.data,
          avatar: `https://gravatar.com/avatar/${md5(classResponse.data.metadata.id)}?s=200&d=identicon&r=x`
        }
        this.courseFetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {

      }
    },
    async getPeople (id) {
      await this.$axios.get(`/organizations/${id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people?roles[]=TEACHER&per_page=5`)
        .then(response => {
          this.teacher = response.data.items
          this.teacherFetch = true
        })
      await this.$axios.get(`/organizations/${id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people?roles[]=STUDENT&per_page=5`)
        .then(response => {
          this.student = response.data.items
          this.studentFetch = true
        })
      // get adms
    },
    async getApplications () {
      await this.$axios.get()
        .then(response => {
          this.application = response.data.items
          this.applicationFetch = true
        })
    },
    handleClick (value) {
      this.$router.push({ path: '/class' })
    },
    async getSchoolData (id) {
      this.schoolFetch = false
      try {
        const classResponse = await this.$axios.get(`organizations/${id}/schools/${this.$route.params.id}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.school = classResponse.data
        this.schoolFetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {

      }
    }
  },
  async mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (id) {
      await this.getCourseData(id)
      this.getPeople(id)
      // this.getApplications(id)
      await this.getSchoolData(id)
      this.breadcrumbs.push(
        {
          text: this.school.data.name,
          disabled: false,
          to: `/school/${this.$route.params.id}`
        },
        {
          text: 'Turmas',
          to: `/school/course/${this.$route.params.id}`
        },
        {
          text: this.course.data.name,
          to: `/course/${this.$route.params.id}/${this.$route.params.course}`
        }
      )
    } else {
      this.$store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma organização' })
    }
  }
}
</script>
